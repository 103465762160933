@import url('https://fonts.googleapis.com/css2?family=Anton&family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
body{
    background-image:   url('../assets/img/bkgimg.jpg');
    box-sizing:border-box;
    position: relative;
    /*padding:10px;Valeur de marges intérieurs*/
}
section{
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 1rem;
    box-sizing:border-box;
}
img,
video {
  height: auto;
  width: auto;
}
h1,h2,h3,h4,h5,h6{
    font-family:Anton , sans-serif;   
}
p{
    font-family:Urbanist , sans-serif;
}

.bigSection, .homeSection{
    display: flex;
    flex-wrap: wrap;
}

.subSectionTtl, .homesubSectionTtl{
    box-sizing:border-box;
    display: inline-flex;
    margin-top: 4rem;
    max-width: 20vw;
    max-height: 100vh;
    margin-right : 1rem;
    margin-left : 1rem;
    margin-bottom: 1rem;
    overflow: none;
    flex: 1 0 0%;
}
.divSectionTtl, .homedivSectionTtl{
    writing-mode: vertical-rl;
    font-weight: 900;
    position: fixed;
    justify-content: center;
    height: 100%;
    width: auto;
    font-size: 3rem;
    overflow: none;
}

.subSectionShow, .homesubSectionShow{
    box-sizing:border-box;
    display: inline-flex;
    margin-top: 4rem;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 80vw;
    max-height: 100vh;
    margin-right : 1rem;
    margin-left : 1rem;
    margin-bottom: 1rem;
    width: 100%;
    flex: 1 0 0%;
}
.divSectionShow, .homedivSectionShow{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
}



/*COMPONENT NAVBAR*/
#navSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: none;
    padding: 0.5rem;
    z-index: 1000;
    position: fixed;
}
#btnBranding{
    width: 20vw;
}
#leMenuNav{
    width: 60vw;
}
#barreDeRecherche{
    width: 20vw;
}
#navSection a{
    font-family:Anton , sans-serif;
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
    margin: 0.5rem;
    padding: 0.2rem;
}
#leMenuNav> *{
    display: inline-flex;
    list-style: none;
    text-decoration: none;
}

/*HELLO PAGE*/
#homepageId{
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 1rem;
}
#helloMediaPart{
    display: inline-flex;
    width: 30vw;
}
#helloTtlPart{
    display: inline-block;
    width: 70vw;
    justify-content: center;
}
#helloTxt{
    align-items: center;
    height: 100vh;
    position: relative;
    align-content: space-around;
}
.helloTtl{
    font-family: Anton, sans-serif;
    font-weight: 900;
    font-size: 5rem;
    margin: 0.5rem;
}

/*MY WORK COMPONENTS*/
.subimgClass{
    display: block;
    max-width: -moz-available;          /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
    margin: 0.5rem;
}
/**/
/*CONTACT COMPONENTS*/
#contactDivPart{
    display: inline-flex;
    width: 100%;
}
#contactInfoPart,#contactFormPart{
    width: 45%;
    height: auto;
    position: relative;
}

#contactFormId *{
    display: block;
    font-family: Anton, sans-serif;
}

#contactFormId> input , textarea{ 
    margin: 0.5rem;
    padding: 0.5rem;
    border: solid 0.5rem ;
    border-radius: 0.2rem;
    width: 85%;
}

textarea#contactmessageId{ 
    height: 30vh;
}
#subpartSocials{
    display: inline-flex;
    flex-wrap: wrap;
}
#subpartSocials .soloSocials a{
    display: flex;
}
.subpartImgSocial{
    display: flex;
    width: 10vw;

}

/*CONSTRUCTION COMPONENTS*/
#constrTtl{
    margin-top: 5rem;
    margin-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
#switchBtn{
    padding: 0.5em;
    background-color: black;
    border: solid 0.2em black;
    border-radius: 0.2rem;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
}
#spanCheckId{
    font-family:Urbanist , sans-serif;
}
.singleSect{
display: inline-flex;
 justify-content: center;
 width: 100%;
 height: 100%;
}
.divsingleSect{
    font-size: 5rem;
}
#constrMsg{
    margin: 0.1em;
}
#divBtnSwitch{
    display: inline-flex;
    margin: 0.1em;
    padding: 0.1em;
}
#switchBtn:active{
    padding: 0.5em;
    background-color: black;
    border: solid 0.1em black;
    border-radius: 0.2rem;
    font-weight: 600;
    font-size: 1.3rem;
    color: white;
}
/*RESPONSIVE PART-------------------------------------------------------------------*/

@media only screen and (max-width: 768px) {

    .bigSection{}
    .subSectionTtl{}
    .divSectionTtl{}
    .subSectionShow{}
    .divSectionShow{}

/*CONSTRUCTION COMPONENTS*/
#constrTtl{
    margin-top: 2rem;
    margin-bottom: 0.1rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    font-size: 50%;
}
#switchBtn{
    padding: 0.4em;
    background-color: black;
    border: solid 0.1em black;
    border-radius: 0.1rem;
    font-weight: 200;
    font-size: 0.9rem;
    color: white;
}
#switchBtn:active{
    padding: 0.4em;
    background-color: black;
    border: solid 0.1em black;
    border-radius: 0.1rem;
    font-weight: 200;
    font-size: 0.7rem;
    color: white;
}
#spanCheckId{
    font-family:Urbanist , sans-serif;
}
.singleSect{
display: inline-flex;
 justify-content: center;
}
.divsingleSect{
    font-size: 5rem;
}
#constrMsg{
    margin: 0.1em;
    font-size: 30%;
}
}